<template>
  <!-- 地址字段 -->
  <div class="address-style">
    <div class="address-botton-box">
      <!-- objectApi {{objectApi}} -->
      <el-button class="address" v-show="
          (objectApi == 'cloudccorder' && formData.accountid) ||
          (objectApi == 'Invoices' && formData.account_name)" @click="changeAddress(item)">{{ $t("c860") }}</el-button>
      <el-button class="address-map" @click="bigMapBtn(item)">{{
        $t("c861")
        }}</el-button>
      <el-button class="address-map" @click="manualInput">手动输入</el-button>
    </div>
    <!-- 地址字段盒子 -->
    <div class="address-box">
      <div v-for="(children, index) in item.children" :key="index" :devid="children.fieldId" :style="{
          width: children.name.includes('04')
            ? '100%'
            : item.fieldType === 'AD' && item.colNum === 1
            ? '50%'
            : '49%',
        }">
        <el-form-item :prop="children.prop" :ref="children.prop">
          <!-- 自定义表单label -->
          <span slot="label">
            <span class="span-box">
              <span class="address-size">{{ children.label }}</span>
            </span>
          </span>
          <!-- 每个输入框 （文本类型） -->
          <!-- children {{ children.name }} -->
          <el-input v-if="isDisplayInput(children) && !children.name.includes('04')" :ref="children.refKey"
            :disabled="!children.edit" :type="children.type" :placeholder="children.placeholder" :maxlength="
              children.apiname === 'jyfw'
                ? 255
                : children.fieldLength === null ||
                  children.fieldLength === undefined
                ? 1000
                : Number(children.fieldLength)
            " auto-complete="off" @blur="
              handleBlurInChild(
                children,
                formData[children.prop],
                formData,
                ...arguments
              )
            " v-model="formData[children.prop]" :class="userInfo.countryCode !== 'CN' ? false : ''" :style="
              item.fieldType === 'AD' && item.colNum === 1
                ? children.name.includes('04')
                  ? 'width: 95%;display:block;'
                  : 'width: 90%;display:block;'
                : 'width: 100%'
            ">
          </el-input>
          <!-- 详细地址类型 -->
          <div v-if="isDetailAddInput(children)" style="display: flex; justify-content: center;">
            <el-input :ref="children.refKey" :disabled="!children.edit" :type="children.type"  
              :placeholder="children.placeholder" :maxlength="
               children.apiname === 'jyfw'
               ? 255
               : children.fieldLength === null ||
             children.fieldLength === undefined
             ? 1000
             : Number(children.fieldLength)" auto-complete="off"
              @blur="handleBlurInChild(children,detailAdd + ' - ' + houseNum ,formData,...arguments)"
              v-model="detailAdd" :class="userInfo.countryCode !== 'CN' ? false : ''">
            </el-input>
            <span style="margin: 0 10px;color: #bbb;">—</span>
            <el-input style="width: 240px;" v-model="houseNum" placeholder="请填写街道、门牌号" :disabled="isHouseNumDisAble"
              @blur="
             handleBlurInChild(
             children,
             detailAdd + ' - ' + houseNum ,
            formData,
             ...arguments
             )
           ">
            </el-input>
          </div>



          <!-- 下拉选择输入框区域 start -->
          <el-select v-if="children.type === 'select' && !children.mapFlag" v-model="formData[children.prop]"
            :disabled="!children.edit" :clearable="true" :multiple="children.type === 'multi-select' ? true : false"
            :filterable="filterable" :placeholder="children.placeholder" :popper-append-to-body="false"
            @visible-change="visibleChange" @change="
              changeEvent(children.changeEvent, arguments[0], children),
                addressChange({
                  item,
                  arg: arguments[0],
                  childrenVal: children,
                  vModelData: formData[children.prop],
                })
            " @blur="types == 'progress' ? selectBlur($event, children) : ''" :style="
              item.fieldType === 'AD' && item.colNum === 1
                ? 'width: 90%;display:block;'
                : 'width: 100%'
            ">
            <!-- 可选列表数据渲染区域 -->
            <template v-for="(opt, index) in optionList[children.prop]">
              <el-option :key="index" style="overflow: visible" :label="opt.key" :value="opt.val">
              </el-option>
            </template>
          </el-select>
          <!-- 下拉选择输入框区域 end -->
        </el-form-item>
      </div>
    </div>
  </div>
</template>

<script>
  import mixinMethods from "../mixins/index";
  import * as CommonObjApi from "../api";
  export default {
    mixins: [mixinMethods],
    props: {
      // 表单中所有select选择器选项集合
      optionList: {
        type: Object,
        default: () => { },
      },
      item: {
        type: Object,
        default: () => { },
      },
      // 表单数据操作类型,有NEW、EDIT等
      operationType: {
        type: String,
        default: "",
      },
      // 是否是新建数据
      isNewBuild: {
        type: Boolean,
        default: false,
      },
      // 表单数据所属记录id
      id: {
        type: String,
        default: "",
      },
      // 使用位置是否在进度条下
      types: {
        type: String,
        default: "",
      },
      // 表单数据所属对象api
      objectApi: {
        type: String,
        default: "",
      },
      filterable: {
        type: Boolean,
        default: false,
      },
      formData: {
        type: Object,
        default: () => { },
      },
      userInfo: {
        type: Object,
      },
    },
    mounted() {
      // 新建地址字段为禁用状态  默认让从地图选择
      if (this.operationType == 'NEW') {
        this.item.children.forEach(children => {
          children.edit = false
        })
        this.isHouseNumDisAble = true
      } else {
        // 编辑
        // 详细地址改成了两个字段 给这两个字段赋值 （只是前端改了 后端没有加字段 前端将两个字段用#拼成一个字段传给了后端）
        // 编辑时 给详细地址的两个字段回显值
        let longitude = this.formData[this.item.prop + "longitude"];
        let latitude = this.formData[this.item.prop + "latitude"];
        if (longitude && latitude) {
          // 只读
          this.item.children.forEach(children => {
            children.edit = false
          })
        } else {
          // 可编辑
          this.item.children.forEach(children => {
            children.edit = true
          })
        }
        this.isHouseNumDisAble = false
        this.detailAdd = this.formData[this.item.prop + "04"].split(' - ')[0]
        // this.houseNum = this.formData[this.item.prop + "04"].split('#')[1]
        let add = this.formData[this.item.prop + "04"]
        this.houseNum = add.indexOf(" - ") == -1 ? "" : add.substr(add.indexOf(" - ") + 3)
      }

      // console.log(this.item, 'item')
      // console.log(this.operationType, 'operationType')
      // console.log(this.formData, 'formData')
      // 解决编辑时，初始化地址地段时下拉还是输入框
      this.addressChange({
        childrenVal: null,
        arg: null,
      });
    },
    methods: {
      // 手动输入
      manualInput() {
        let longitude = this.formData[this.item.prop + "longitude"];
        let latitude = this.formData[this.item.prop + "latitude"];
        if (longitude && latitude) {
          // 发票明细的产品名称如果有值
          this.$confirm(
            "此操作会清除定位信息，是否继续操作？",
            "提示",
            {
              confirmButtonText: "是",
              cancelButtonText: "否",
              type: "warning",
            }
          )
            .then(() => {
              this.item.children.forEach(children => {
                children.edit = true
              })
              this.isHouseNumDisAble = false
              // 手动输入有经纬度清空经纬度
              this.$set(this.formData, this.item.prop + "longitude", '')
              this.$set(this.formData, this.item.prop + "latitude", '')
            })
            .catch(() => { });
        } else {
          this.item.children.forEach(children => {
            children.edit = true
          })
          this.isHouseNumDisAble = false
        }
        // console.log(latitude, 'latitude')
        // console.log(longitude, 'longitude')
      },
      // 是否显示输入框
      isDisplayInput(children) {
        return (
          children.type === "input" &&
          !children.query &&
          !children.filterable &&
          !children.isAdress &&
          !(
            children.name.indexOf("longitude") !== -1 ||
            children.name.indexOf("latitude") !== -1
          )
        );
      },
      // 详细信息input
      isDetailAddInput(children) {
        return (
          children.type === "input" &&
          !children.query &&
          !children.filterable &&
          !children.isAdress &&
          children.parentType === 'AD' &&
          children.parentId &&
          children.name.slice(-2) === '04' &&
          !(
            children.name.indexOf("longitude") !== -1 ||
            children.name.indexOf("latitude") !== -1
          )
        )
      },
      selectBlur(e, item) {
        this.$emit("selectBlur", e, item);
      },
      changeEvent(callback, selectVal, selectItem, fieldType) {
        this.$emit("changeEvent", callback, selectVal, selectItem, fieldType);
      },
      /**
       * 地址字段更改地址
       * @param {Object} item 地址字段字段信息
       */
      async changeAddress(item) {
        let newAdress = {
          edit: item.edit,
          fieldId: "",
          label: this.$i18n.t("LT"), //地理定位
          name: item.prop,
          required: false,
          type: "S",
          isAdress: true,
          mapFlag: true,
          prop: item.prop,
        };
        this.clearItemArr = newAdress;
        let type =
          item.apiname == "billingaddress" || item.apiname == "billing_address"
            ? "billing"
            : "shipping";
        this.addressType = type;
        let objName = this.objectApi == "cloudccorder" ? "order" : "invoice";
        CommonObjApi.getPermissionById({
          id: this.formData.accountid || this.formData.account_name,
        }).then(async (res) => {
          if (res.result && res.data.isQuery == true) {
            this.showAddressDialog = true;
            let result = await CommonObjApi.getCustomerAddress({
              customerId: this.formData.accountid || this.formData.account_name,
              recordId: this.id,
              type: type,
              objName: objName,
            });
            if (result.result == true) {
              if (result.data.length > 0) {
                if (
                  this.operationType != "NEW" &&
                  !this.isNewBuild &&
                  result.data[0]
                ) {
                  //将当前地址字段更改为组件适用字段
                  result.data[0].address = result.data[0].shippingaddress
                    ? result.data[0].shippingaddress
                    : result.data[0].billingaddress;
                  result.data[0].phonenumber = result.data[0].shippingcontactphone
                    ? result.data[0].shippingcontactphone
                    : result.data[0].billingcontactphone;
                }
              }
              this.addressArr = result.data;
            }
          } else {
            this.$message.warning(this.$t("c657"));
          }
        });
      },
      /**
       * 地址字段 从地图选择
       * @param {Object} item 地址字段信息
       */
      bigMapBtn(item) {
        this.$emit("bigMapBtnFn", item);
      },
      /**
       * 内部组件处理鼠标移出事件   发射给父组件调用
       * 并发送给 src\views\commonObjects\components\create-edit-obj.vue
       * @param {Object} item 字段信息
       * @param {String} val 字段值
       * @param {Object} formData 表单绑定的数据
       */
      handleBlurInChild(item, val, formData) {
        this.$emit("handleBlurInChild", item, val, formData);
      },
      /**
       * 地址下拉改变，判断显示下拉还是输入框
       *
       * 这个方法发射事件给 src\components\Form\index.vue 组件监听
       * 并发送给 src\views\commonObjects\components\create-edit-obj.vue 改变表单模板的数据
       */
      addressChange({ childrenVal, arg }) {
        /**
         * arg 选择的数据值
         *    例如：北京市
         */
        /**
         * childrenVal  当前选择的布局的item数据
         */
        if (childrenVal?.seq === "1") {
          // 省/辖区 -> 找市区
          this.$emit("changeEvent", "getCity", arg, childrenVal);
        } else if (childrenVal?.seq === "2") {
          // 市区 -> 找县城
          this.$emit("changeEvent", "getCounty", arg, childrenVal);
        }
        /**
         * 找出国家的 prop 值
         */
        let { prop } = this.item.children.find((v) => v.seq === "0");

        /**
         * 找出表单对象中 等于中国的数据，进行判断是否改变城市，县城的选择展示方式
         * 不区分当前选择的是第几个输入框
         */
        if (this.formData[prop] == "中国") {
          this.item.children.forEach((itemx) => {
            if (itemx.seq === "2" || itemx.seq === "3") {
              itemx.type = "select";
            } else {
              if (itemx.seq !== "0" && itemx.seq !== "1") {
                itemx.type = "input";
              }
            }
          });
        } else {
          this.item.children.forEach((itemx) => {
            if (itemx.seq == "0" || itemx.seq == "1") {
              itemx.type = "select";
            } else {
              itemx.type = "input";
            }
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .address-style {
    position: relative;

    .address-botton-box {
      position: absolute;
      right: 0;
      top: -20px;

      .address-map {
        background: $--color-primary;
        color: $--color-white;
      }

      .address {
        border: 1px solid $--border-color-base;
        background: $--color-white;
        color: #000;
      }

      .el-button {
        font-size: 12px;
        padding: 7px;
      }
    }

    .address-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  ::v-deep .address-style .el-form-item__content {
    width: 100% !important;
  }

  // 地址字段一列显示时样式
  ::v-deep .address-field .el-form-item__content {
    width: 95%;

    .address-botton-box {
      right: 5%;
    }
  }

  .address-size {
    font-size: 12px;
  }
</style>